import React, { useEffect, useState, useRef } from "react"

import Layout from '../components/layout'
import Seo from "../components/seo"
import {
  Container,
  Text,
  Box,
  Heading
} from '@chakra-ui/react'
import GreenHouseCareer from "../components/CareersPage/GreenHouseCareer"

const CareersPage = (props) => {

  const [jobData, setJobData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const scriptElement = useRef(null);
  
  const API = `https://boards-api.greenhouse.io/v1/boards/rippleneurotechventures/jobs?content=true`;

  useEffect(() => {
    fetch(API).then(response => response.json())
      .then(data => {
        setLoaded(true);
        let jobs = data.jobs;
        setJobData(jobs);
      })
  }, [API]);
  
  return (
    
  <Layout
    annoucementDisplay="none"
  >
  <Seo title="Iris Biomedical | Careers" />
  <Container maxWidth="container.xl" my="12">
  <Box w={{sm: '100%', md: '100%'}}>
            <Text variant="sub-head" textAlign="center">
                Careers
            </Text>
            </Box>
    <Text textAlign="center" fontSize="1.5rem">
    </Text>
    {jobData ? jobData.map((job, index) => {
      console.log(jobData)
      return (
        <GreenHouseCareer
          job={job} 
          key={index} 
        />
      )
    }) : <Heading as="h2" fontWeight="600" textAlign="center" mb="2">
    There are currently no open positions. Please check back again soon.
  </Heading>}
    
  </Container>
  
  </Layout>
  )
}


export default CareersPage
