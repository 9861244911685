import React from "react"
import { Box, Flex, Text, Heading, Button } from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"

const GreenHouseCareer = ( job ) => {
  console.log(job)
  return (
    <Box
      my="4"
      p={{ sm: "4", md: "16" }}
      style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: "20px" }}
    >
      <Heading fontSize={{ sm: "2rem", md: "2.25rem" }}>{job.job.title}</Heading>
      <Text fontSize="1.25rem" color="steel.700" fontWeight="300">
        {job.job.location.name}
      </Text>
      <Flex justifyContent="flex-end">
          <Box>
            <a
              href={job.job.absolute_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="ctaButtonOutline">
                Learn More <ChevronRightIcon />
              </Button>
            </a>
          </Box>
      </Flex>
    </Box>
  )
}

export default GreenHouseCareer
